import { DataList } from '@/components';
import { getErrorDescription } from '@/pages/moves/upload/utils/getErrorDescription';
import { getFieldNames, getLines } from '@/pages/moves/upload/utils/utils';
import { MovesValidationErrorDetail } from '@/services/files/endpoints/moves';
import translateRulesValidator from '@/utils/translateRulesValidator';
import React from 'react';

interface DataTableErrorsProps {
  details: MovesValidationErrorDetail[];
}

const DataTableErrors: React.FC<DataTableErrorsProps> = ({ details }) => {
  return (
    <DataList
      noPagination
      elevation={4}
      data={details.filter((error) => !error.skippable)}
      columns={[
        {
          darken: true,
          title: 'Linha',
          value: getLines,
        },
        {
          darken: true,
          title: 'Coluna',
          value: getFieldNames,
        },
        {
          darken: true,
          title: 'Valor encontrado',
          value: 'value',
        },
        {
          darken: true,
          title: 'Valor esperado/problema',
          value: (movesValidationErrorDetail: MovesValidationErrorDetail) =>
            translateRulesValidator(
              movesValidationErrorDetail.rule,
              movesValidationErrorDetail.details?.expected,
            ) || getErrorDescription(movesValidationErrorDetail),
        },
      ]}
    />
  );
};

export default DataTableErrors;
